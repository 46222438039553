import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 药物组检索检索
export function list(query) {
  return request({
    url: '/dkm/drugGroup/list',
    method: 'post',
    params: query
  })
}



// 根据药物组检索ID查询药物组检索明细
export function info(id) {
  return request({
    url: '/dkm/drugGroup/info/' + praseStrEmpty(id),
    method: 'get'
  })
}

// 删除药物组检索
export function del(id) {
  return request({
    url: '/dkm/drugGroup/del/' + praseStrEmpty(id),
    method: 'get'
  })
}


// 新增或修改药物组检索
export function saveOrUpdate(data) {
  return request({
    url: '/dkm/drugGroup/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 药物组启用-停用
export function updateStatus( drugGroupId,status) {
  return request({
    url: '/dkm/drugGroup/updateStatus',
    method: 'post',
    params: {drugGroupId:drugGroupId,status:status}
  })
}

// 根据药物名称、别名、代码、拼音、五笔码等模糊查询前100条药物名称
export function selectDrugNameList(query) {
  return request({
    url: '/dkm/drug/selectDrugNameList',
    method: 'post',
    params: query
  })
}
// 根据药物组名称、拼音、五笔码等模糊查询前100条药物组名称
export function selectDrugGroupNameList(query) {
  return request({
      url: '/dkm/drugGroup/selectDrugGroupNameList',
      method: 'post',
      params: query
  })
}
// 检查药物组相关数据重复项
export function checkFields(data) {
  return request({
    url: '/dkm/drugGroup/checkFields',
    method: 'post',
    params: data
  })
}
