/**
 * 药品相关
 */
import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 查询药品列表
export function list(query) {
  return request({
    url: '/dkm/drugProd/list',
    method: 'post',
    params: query
  })
}


// 查询单个药品的明细
export function info(drugProdId) {
  return request({
    url: '/dkm/drugProd/info/' + praseStrEmpty(drugProdId),
    method: 'get'
  })
}


//复制新增药品
export function copy(drugProdId) {
  return request({
    url: '/dkm/drugProd/copy/' + praseStrEmpty(drugProdId),
    method: 'get'
  })
}

// 更新药品状态--0正常 1停用  activePkgDrugProd 是否启用所有关联的包装药品,仅启用药品时有效
export function updateStatus(drugProdId,status,activePkgDrugProd) {
  return request({
    url: '/dkm/drugProd/updateStatus',
    method: 'post',
    params: {drugProdId:drugProdId,status:status,activePkgDrugProd:activePkgDrugProd}
  })
}


// 药品名称关键字检索
export function selectDrugProdNameList(queryStr) {
  return request({
    url: '/dkm/drugProd/selectDrugProdNameList',
    method: 'post',
    params: {queryStr:queryStr}
  })
}

// 新增 或 修改 药品
export function saveOrUpdate(data) {
  return request({
    url: '/dkm/drugProd/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 删除药品
export function del(id) {
  return request({
    url: '/dkm/drugProd/del/' + praseStrEmpty(id),
    method: 'post'
  })
}


// 新增药品时，自动获取到同类型药物的成分，引入到编辑页面
export function getRecommendSpecIngredient(id) {
  return request({
    url: '/dkm/drugProd/getRecommendSpecIngredient/' + praseStrEmpty(id),
    method: 'post'
  })
}


// 检查药品相关数据重复项，如编辑或新增时候，检查本位码是否有重复，批准文号是否有重复等
export function checkFields(fieldName,fieldValue,drugProdId) {
  return request({
    url: '/dkm/drugProd/checkFields',
    method: 'post',
    params: {fieldName:fieldName,fieldValue:fieldValue,drugProdId:drugProdId}
  })
}


// 查询包装药品列表
export function pdkDrugProdList(query) {
  return request({
    url: '/dkm/pkgDrugProd/list',
    method: 'post',
    params: query
  })
}


// 根据本位码和批准文号查询药品包装材料列表(包含包装药品信息)
export function selectPdProdMatList(query) {
  return request({
    url: '/dkm/pkgDrugProd/selectPdProdMatList',
    method: 'post',
    params: query
  })
}


// 更新医保编码
export function updateMedicareNum(matId, stateMedicareNum, shMedicareNum) {
  return request({
    url: '/dkm/pkgDrugProd/updateMedicareNum',
    method: 'post',
    params: {matId:matId,stateMedicareNum:stateMedicareNum,shMedicareNum:shMedicareNum}
  })
}


// 检查本位码和制剂规格id组合是否重复
export function checkStandardCodeAndPpSpecId(standardCode,ppSpecId,drugProdId) {
  return request({
    url: '/dkm/drugProd/checkStandardCodeAndPpSpecId',
    method: 'post',
    params: {standardCode:standardCode,ppSpecId:ppSpecId,drugProdId:drugProdId}
  })
}


