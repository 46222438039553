import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'
import qs from 'qs'

// 查询物质列表
export function list(query) {
  return request({
    url: '/dkm/substance/list',
    method: 'post',
    params: query
  })
}



// 查询单个物质明细
export function info(id) {
  return request({
    url: '/dkm/substance/info/' + praseStrEmpty(id),
    method: 'get'
  })
}

// 删除物质
export function del(id) {
  return request({
    url: '/dkm/substance/del/' + praseStrEmpty(id),
    method: 'get'
  })
}


// 新增物质 或 修改物质
export function saveOrUpdate(data) {
  return request({
    url: '/dkm/substance/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 更新物质状态--0正常 1停用
export function updateStatus( substanceId,status) {
  return request({
    url: '/dkm/substance/updateStatus',
    method: 'post',
    params: {substanceId:substanceId,status:status}
  })
}


// 查询unii编码或名称
export function selectUniiOrNames(query) {
  return request({
    url: '/dkm/substance/selectUniiOrNames',
    method: 'post',
    params: query
  })
}


// 根据unii编码查询物质结构信息
export function selectUniiRecord(unii) {
  return request({
    url: '/dkm/substance/selectUniiRecord',
    method: 'post',
    params: {unii:unii}
  })
}

// 根据物质名称、代码、拼音、五笔码等模糊查询前100条物质名称
export function selectSubstanceNameList(queryStr) {
  return request({
    url: '/dkm/substance/selectSubstanceNameList',
    method: 'post',
    params: {queryStr:queryStr}
  })
}

// 检查物质名称是否已存在
export function checkSubstanceName(substanceName,substanceId) {
  return request({
    url: '/dkm/substance/checkSubstanceName',
    method: 'post',
    params: {substanceName:substanceName,substanceId:substanceId}
  })
}
// 药物:根据物质id查询含有该物质的药物列表
export function drugSelectBySubstanceId(data) {
  return request({
    url: '/dkm/drug/selectBySubstanceId',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data)||{}
  })
}

// 药品:根据物质id查询含有该物质的药品列表
export function drugProdSelectBySubstanceId(data) {
  return request({
    url: '/dkm/drugProd/selectBySubstanceId',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data)||{}
  })
}
// 制剂查询列表
export function preparationList(data) {
  return request({
    url: '/dkm/preparation/list',
    method: 'post',
    data: data
  })
}

// 获取指定数据的全部分类信息
export function getDrugCategoryTree(data) {
  return request({
    url: '/dkm/category/dir/getDrugCategoryTree',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data)||{}
  })
}



// 更新cas编码
export function updateCas(substanceId, unii,cas) {
  return request({
    url: '/dkm/substance/updateCas',
    method: 'post',
    params: {substanceId:substanceId,unii:unii,cas:cas}
  })
}
