import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'
import qs from "qs";

// 药物检索
export function list(query) {
  return request({
    url: '/dkm/drug/list',
    method: 'post',
    params: query
  })
}



// 根据药物ID查询药物明细
export function info(id) {
  return request({
    url: '/dkm/drug/info/' + praseStrEmpty(id),
    method: 'get'
  })
}

// 删除药物
export function del(id) {
  return request({
    url: '/dkm/drug/del/' + praseStrEmpty(id),
    method: 'get'
  })
}


// 新增或修改药物
export function saveOrUpdate(data) {
  return request({
    url: '/dkm/drug/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 更新物质状态--0正常 1停用
export function updateStatus( drugId,status) {
  return request({
    url: '/dkm/drug/updateStatus',
    method: 'post',
    params: {drugId:drugId,status:status}
  })
}

// 根据药物名称、别名、代码、拼音、五笔码等模糊查询前100条药物名称
export function selectDrugNameList(queryStr) {
  return request({
    url: '/dkm/drug/selectDrugNameList',
    method: 'post',
    params: {queryStr:queryStr}
  })
}

// 根据药物组名称、拼音、五笔码等模糊查询前100条药物组名称
export function selectDrugGroupNameList(query) {
  return request({
    url: '/dkm/drugGroup/selectDrugGroupNameList',
    method: 'post',
    params: query
  })
}
// 根据物质名称、代码、拼音、五笔码等模糊查询前100条物质名称
export function selectSubstanceNameList(query) {
  return request({
    url: '/dkm/substance/selectSubstanceNameList',
    method: 'post',
    params: query
  })
}
// 检查药物相关数据重复项
export function checkFields(data) {
  return request({
    url: '/dkm/drug/checkFields',
    method: 'post',
    params: data
  })
}



// 根据成分物质ID查询只含有该物质的单成分药物明细
export function oligomictDrugInfo(substanceId) {
  return request({
    url: '/dkm/drug/oligomictDrugInfo/' + praseStrEmpty(substanceId),
    method: 'get'
  })
}
// 药物:根据药物id查询成分相似药物列表
export function selectSimilarityIngredientDrugList(data) {
  return request({
    url: '/dkm/drug/selectSimilarityIngredientDrugList',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data)||{}
  })
}

// 药品:根据药物id查询属于该药物的药品列表
export function selectByDrugId(data) {
  return request({
    url: '/dkm/drugProd/selectByDrugId',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data)||{}
  })
}
// 制剂查询列表
export function preparationList(data) {
  return request({
    url: '/dkm/preparation/list',
    method: 'post',
    data: data
  })
}
