import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 根据类型获取相关字典值
// export function dict(id) {
//   return request({
//     url: '/dkm/preparation/dict/' + praseStrEmpty(id),
//     method: 'get'
//   })
// }
export function dict(id,searchValue) {
  return request({
    url: '/dkm/preparation/dict/' + praseStrEmpty(id),
    method: 'get',
    params: {searchValue:searchValue}
  })
}
// 制剂查询列表
export function preparationList(data) {
  return request({
    url: '/dkm/preparation/list',
    method: 'post',
    data: data
  })
}
/*根据制剂别名分页查询制剂规格列表（药品管理使用）*/
export function querySpecList(query) {
  return request({
    url: '/dkm/preparation/spec/querySpecList',
    method: 'post',
    params: query
  })
}
// 可以添加的用药部位列表
export function siteEnable(query) {
  return request({
    url: '/dkm/preparation/site/enable',
    method: 'get',
    params: query
  })
}
// 可以添加的用药途径列表
export function routeEnable(query) {
  return request({
    url: '/dkm/preparation/route/enable',
    method: 'get',
    params: query
  })
}
// 新增-更新制剂
export function preparationUpdata(data) {
  return request({
    url: '/dkm/preparation',
    method: 'post',
    data: data
  })
}
// 修改制剂规格（规格）
export function modifySpec(data) {
  return request({
    url: '/dkm/preparation/spec',
    method: 'put',
    data: data
  })
}
// 新增制剂规格（规格）
export function addSpec(data) {
  return request({
    url: '/dkm/preparation/spec',
    method: 'post',
    data: data
  })
}
// 删除规格（规格）
export function delSpec(query) {
  return request({
    url: '/dkm/preparation/spec',
    method: 'DELETE',
    params: query
  })
}
// 制剂规格-设为首选规格
export function defaultSpec(query) {
  return request({
    url: '/dkm/preparation/spec/default',
    method: 'put',
    params: query
  })
}
// 制剂规格 启用-停用
export function statusSpec( id, status) {
  return request({
    url: '/dkm/preparation/spec/status',
    method: 'put',
    params: {id:id,status:status}
  })
}
// 根据ID查询制剂
export function getpreparationInfo(id) {
  return request({
    url: '/dkm/preparation/' + praseStrEmpty(id),
    method: 'get'
  })
}
// 根据制剂ID获取制剂规格集合
export function getSpecInfo(id) {
  return request({
    url: '/dkm/preparation/spec/preparation/' + praseStrEmpty(id),
    method: 'get'
  })
}
// 制剂的别名列表
export function getAliasInfo(id) {
  return request({
    url: '/dkm/preparation/alias/preparation/' + praseStrEmpty(id),
    method: 'get'
  })
}
// 删除制剂
export function delPreparation(query) {
  return request({
    url: '/dkm/preparation',
    method: 'DELETE',
    params: query
  })
}
// 修改制剂规格
export function modifyPreparation( id,status) {
  return request({
    url: '/dkm/preparation',
    method: 'put',
    params: {id:id,status:status}
  })
}
// 根据制剂名称模糊搜索制剂名-前50条
export function selectDrugNameList(searchValue) {
  return request({
    url: '/dkm/preparation/name/select',
    method: 'get',
    params: {searchValue:searchValue}
  })
}
// 用药途径管理列表-已经添加了的复制接口复制文档复制地址
export function route(preparationId) {
  return request({
    url: '/dkm/preparation/route',
    method: 'get',
    params: {preparationId:preparationId}
  })
}
// 批量新增-更新用药途径
export function modifyRoute(data) {
  return request({
    url: '/dkm/preparation/route',
    method: 'post',
    data: data
  })
}
// 给药部位管理列表-该制剂已经有的用药部位
export function site(preparationId) {
  return request({
    url: '/dkm/preparation/site',
    method: 'get',
    params: {preparationId:preparationId}
  })
}
// 用药部位新增-更新
export function modifySite(data) {
  return request({
    url: '/dkm/preparation/site',
    method: 'post',
    data: data
  })
}
// 对药剂别名进行修改
export function modifyAlias(data) {
  return request({
    url: '/dkm/preparation/alias',
    method: 'post',
    data: data
  })
}
// 查询剂型关联的给药途径
export function selectAdminRoute(formConceptId) {
  return request({
    url: '/term/conceptRelForm/selectAdminRoute/' + praseStrEmpty(formConceptId),
    method: 'get'
  })
}
// 查询剂型关联的用药部位
export function selectAppSite(formConceptId) {
  return request({
    url: '/term/conceptRelForm/selectAppSite/' + praseStrEmpty(formConceptId),
    method: 'get'
  })
}
// 根据概念域代码查询概念信息
export function getConceptsByDomainCode(data) {
  return request({
    url: '/term/concept/getConceptsByDomainCode',
    method: 'get',
    params: data
  })
}
// 批量新增-修改制剂规格
export function addSpecBatch(id, data) {
  return request({
    url: '/dkm/preparation/spec/batch?preparationId=' + praseStrEmpty(id),
    method: 'post',
    data: data
  })
}
// 计算浓度（返回的是百分比，不带%）
export function getConcentration(data) {
  return request({
    url: 'dkm/preparation/spec/getConcentration',
    method: 'post',
    params: data
  })
}
// 检查制剂规格是否存在相同的项
export function checkExistSameSpec(data) {
  return request({
    url: '/dkm/preparation/spec/checkExistSameSpec',
    method: 'post',
    data: data
  })
}
// 检查制剂规格是否存在相同的项
export function analyseSpec(data) {
  return request({
    url: '/dkm/preparation/spec/analyseSpec',
    method: 'post',
    params: data
  })
}

// 检查制剂相关数据重复项
export function checkFields(data) {
  return request({
    url: '/dkm/preparation/checkFields',
    method: 'post',
    params: data
  })
}
// 规格模式下单位
export function specModeUnit() {
  return request({
    url: '/dkm/preparation/specMode/unit',
    method: 'get'
  })
}
// 根据规格id 获取相关规格的成分
export function specIngredient(id) {
  return request({
    url: '/dkm/preparation/spec/ingredient/' + praseStrEmpty(id),
    method: 'get'
  })
}
// 根据规格id 编辑成分
export function editSpecIngredient(data) {
  return request({
    url: '/dkm/preparation/spec/ingredient',
    method: 'put',
    data: data
  })
}
